// Generated by Framer (a4439fa)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./jXLvgHvzy-0.js";
const MotionDivWithFX = withFX(motion.div);

const enabledGestures = {xKWHtwOfj: {hover: true}};

const cycleOrder = ["xKWHtwOfj"];

const serializationHash = "framer-AilUv"

const variantClassNames = {xKWHtwOfj: "framer-v-1h3n4r3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {UqrkdNuY1: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xKWHtwOfj", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} __framer__animate={{transition: transition1}} __framer__animateOnce={false} __framer__scrollDirection={{direction: "down", target: animation}} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1h3n4r3", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xKWHtwOfj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(96, 82, 228)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", filter: "blur(182px)", opacity: 1, WebkitFilter: "blur(182px)", ...style}} variants={{"xKWHtwOfj-hover": {opacity: 0.31}}} {...addPropertyOverrides({"xKWHtwOfj-hover": {__targetOpacity: 0.31, "data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AilUv.framer-1agzfik, .framer-AilUv .framer-1agzfik { display: block; }", ".framer-AilUv.framer-1h3n4r3 { cursor: pointer; height: 867px; overflow: visible; position: relative; width: 867px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 867
 * @framerIntrinsicWidth 867
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uMUy89XxF":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjXLvgHvzy: React.ComponentType<Props> = withCSS(Component, css, "framer-AilUv") as typeof Component;
export default FramerjXLvgHvzy;

FramerjXLvgHvzy.displayName = "Ellipse 746";

FramerjXLvgHvzy.defaultProps = {height: 867, width: 867};

addFonts(FramerjXLvgHvzy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})